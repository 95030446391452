import React, { useEffect, useContext,useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { AuthContext } from "./contexts/LoginContext";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Calendar,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Line,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
} from "./pages";
import "./App.css";

//MainPage
import Distributor from "./pages/distributor/Distributor";
import OnlineTransaction from "./pages/onlineTransaction/OnlineTransaction";
import AppUsers from "./pages/appUsers/AppUsers";
import Orders from "./pages/order/Order";
import Service from "./pages/Service/Service";
import OrderPayments from "./pages/orderPayments/OrderPayments";
import EmployeeWiseOrders from "./pages/employeeWiseOrders/EmployeeWiseOrders";
import Merchants from "./pages/merchant/Merchants";
import Employees from "./pages/employee/Employees";
import DeliveryRate from "./pages/deliveryRate/DeliveryRate";
import OffersAndBanners from "./pages/offers&Banners/Banner";
import PromotionalDiscount from "./pages/promotionalDiscount/PromotionalDiscount";
import GroupPromotionalDiscount from "./pages/groupPromotinalDiscount/GroupPromotionalDiscount";
import SendNotificationTo from "./pages/SendNotificationTo";

import { useStateContext } from "./contexts/ContextProvider";
import { useSetState } from "react-use";
//Authentication
import Register from "./pages/auth/Register";
import Auth from "./pages/login/Login";
import EditProfile from "./pages/EditProfile/EditProfile";

//AddPanels
//import AddMerchant from "./pages/merchant/AddMerchant";
import Addservice from "./pages/Service/Addservice"
import AddEmployee from "./pages/employee/AddEmployee";
import AddDeliveryRate from "./pages/deliveryRate/AddDeliveryRate";
import AddOffersAndBanners from "./pages/offers&Banners/AddBanner";
import AddPromotionalDiscount from "./pages/promotionalDiscount/AddPromotionalDiscount";
import AddGroupPromotionalDiscount from "./pages/groupPromotinalDiscount/AddGroupPromotionalDiscount";

import ConfirmOrder from "./pages/order/ConfirmOrder";

//ViewPabels
import ViewOrder from "./pages/order/ViewOrder";
import AssignOrder from "./pages/order/AssignOrder";
import ViewAppUsers from "./pages/appUsers/ViewAppUsers";
import ViewOnlineTransaction from "./pages/onlineTransaction/ViewOnlineTransaction";
import ViewOrderPayment from "./pages/orderPayments/ViewOrderPayment";
import ViewEmployeeWiseOrders from "./pages/employeeWiseOrders/ViewEmployeeWiseOrders";
import ViewMerchant from "./pages/merchant/ViewMerchant";
import ViewEmployee from "./pages/employee/ViewEmployee";
import ViewDeliveryRate from "./pages/deliveryRate/ViewDeliveryRate";
import ViewOfferAndBanner from "./pages/offers&Banners/ViewOfferAndBanner";
import ViewPromotionalDiscount from "./pages/promotionalDiscount/ViewPromotionalDiscount";
import ViewGroupDiscount from "./pages/groupPromotinalDiscount/ViewGroupDiscount";
import Banners from "./pages/banner/Banner";
import AddBanner from "./pages/banner/AddBanner";

//EditPanels
import EditMerchant from "./pages/merchant/EditMerchant";
import EditEmployee from "./pages/employee/EditEmployee";
import EditDeliveryRate from "./pages/deliveryRate/EditDeliveryRate";
import EditOffersAndBanners from "./pages/offers&Banners/EditOffersAndBanners";
import EditPromotionalDiscount from "./pages/promotionalDiscount/EditPromotionalDiscount";
import EditGroupPromotionalDiscount from "./pages/groupPromotinalDiscount/EditGroupPromotionalDiscount";
import AddMerchant1 from "./pages/merchant/AddMerchant1";
import EditBanner from "./pages/banner/EditBanner";
import Editservice from "./pages/Service/Editservics"
// import AlertEdit from "./pages/merchant/AlertDialog";
import PrivacyPolicy from './pages/PrivacyPolicy'
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import OTPVerify from "./pages/forgotPassword/OTPVerify";
import ChangePWD from "./pages/forgotPassword/ChangePWD";
// import { onMessageListener } from "./firebaseInit";
// import Notifications from "./components/Notifications/Notifications";
// import ReactNotificationComponent from "./components/Notifications/ReactNotification";
import { getToken, onMessageListener } from './firebaseInit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    auth,reset,displayOTP,changePwd
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const [token_fcm, setToken] = useState('');
  const [message, setMessage] = useState('');

    const fetchToken = async () => {
      const currentToken = await getToken();
      setToken(currentToken);
      sessionStorage.setItem("FCM_TOKEN", token_fcm)
    };

    const handleMessage = async () => {
      const payload = await onMessageListener();
      console.log("payload", payload.notification, payload)
      const notificationBar = `${payload.notification.title}: ${payload.notification.body}`
      toast.info(notificationBar, { autoClose: 2000, closeOnClick: true,});
      setMessage(payload);
    };

    fetchToken();
    handleMessage();

    console.log("message", message)

  const admin = false;
  // // // Firebase start
  // const [show, setShow] = useState(false);
  // const [notification, setNotification] = useState({ title: "", body: "" });

  // console.log(show, notification);

  // onMessageListener()
  //   .then((payload) => {
  //     setShow(true);
  //     setNotification({
  //       title: payload.notification.title,
  //       body: payload.notification.body,
  //     });
  //     console.log(payload);
  //     setTimeout(function(){ setShow(false) }, 3000);
  //   })
  //   .catch((err) => console.log("failed: ", err));


  // // //   Firebase end
  return (
    <div>
      
      {/* <Notifications />
      {show ? (
        <ReactNotificationComponent
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )} */}
      {/* {!auth ? ( */}
    
      <BrowserRouter>
      <Routes>
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/*" element={<Main auth={auth} reset={reset} displayOTP={displayOTP} changePwd={changePwd} currentMode={currentMode} setThemeSettings={setThemeSettings} activeMenu={activeMenu} themeSettings={themeSettings} currentColor={currentColor} />} />
       
      
      </Routes>

 
      </BrowserRouter>
    </div>
  );
};


export default App;
export const Main=({auth,reset,displayOTP,changePwd,currentMode,setThemeSettings,activeMenu,themeSettings,currentColor})=>{
 return (
   <>
     {reset ? (
       <ForgotPassword />
     ) : displayOTP ? (
       <OTPVerify />
     ) : changePwd ? (
       <ChangePWD />
     ) : !auth ? (
       <Auth />
     ) : (
       <div className={currentMode === "Dark" ? "dark" : ""}>
         <div className="flex relative dark:bg-main-dark-bg">
           <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
             <TooltipComponent content="Settings" position="Top">
               <button
                 type="button"
                 onClick={() => setThemeSettings(true)}
                 style={{ background: currentColor, borderRadius: "50%" }}
                 className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
               >
                 <FiSettings />
               </button>
             </TooltipComponent>
           </div>
           {activeMenu ? (
             <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
               <Sidebar />
             </div>
           ) : (
             <div className="w-0 dark:bg-secondary-dark-bg">
               <Sidebar />
             </div>
           )}
           <div
             className={
               activeMenu
                 ? "dark:bg-main-dark-bg  bg-main-bg md:ml-72 w-full"
                 : "dark:bg-main-dark-bg  bg-main-bg w-100"
                //  : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
             }
           >
             <div
               className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full "
               style={{
                 backgroundColor: "rgb(128 128 128 / 14%)",
                 boxShadow:
                   "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
               }}
             >
               <Navbar />
             </div>
             <div>
               {themeSettings && <ThemeSettings />}

               <Routes>
                 {/* dashboard  */}
                 {/* <Route path="/" element={<Ecommerce />} />
   <Route path="/ecommerce" element={<Ecommerce />} /> */}
                 <Route path="/" element={<Distributor />} />
                 <Route path="/distributor" element={<Distributor />} />
                 {/* authentication */}
                 <Route path="/register" element={<Register />} />
                 {/* <Route path="/login" element={<Auth />} /> */}
                 {/* pages  */}
                 <Route
                   path="/onlineTransaction"
                   element={<OnlineTransaction />}
                 />
                 <Route path="/appusers" element={<AppUsers />} />
                 <Route path="/orders" element={<Orders />} />
                 <Route path="/Service" element={<Service />} />
                 <Route path="/Addservice" element={<Addservice />} />
                 <Route path="/Editservice" element={<Editservice />} />
                 <Route path="/orderpayments" element={<OrderPayments />} />
                 <Route
                   path="/employeewiseorders"
                   element={<EmployeeWiseOrders />}
                 />
                 {/* <Route path="/alertedit" element={<AlertEdit />} /> */}
                 <Route path="/Add/merchant" element={<AddMerchant1 />} />
                 <Route path="/merchants" element={<Merchants />} />
                 <Route path="/employees" element={<Employees />} />
                 <Route path="/deliveryrate" element={<DeliveryRate />} />
                 {/* <Route
           path="offersandbanners"
           element={<OffersAndBanners />}
         /> */}
                 <Route
                   path="/promotionaldiscount"
                   element={<PromotionalDiscount />}
                 />
                 <Route
                   path="/grouppromotionaldiscount"
                   element={<GroupPromotionalDiscount />}
                 />
                 //Add Route//
                 <Route path="/employee/add" element={<AddEmployee />} />
                 <Route
                   path="/deliveryrate/add"
                   element={<AddDeliveryRate />}
                 />
                 <Route path="/Banners" element={<Banners />} />
                 <Route path="/Banner/add" element={<AddBanner />} />
                 <Route
                   path="/promotionaldiscount/add"
                   element={<AddPromotionalDiscount />}
                 />
                 <Route
                   path="/grouppromotionaldiscount/add"
                   element={<AddGroupPromotionalDiscount />}
                 />
                 //EditRoute//
                 <Route path="/editprofile" element={<EditProfile />} />
                 <Route path="/merchants/edit" element={<EditMerchant />} />
                 <Route path="/banners/edit" element={<EditBanner />} />
                 <Route path="/employees/edit" element={<EditEmployee />} />
                 <Route
                   path="/deliveryrate/edit"
                   element={<EditDeliveryRate />}
                 />
                 <Route
                   path="/promotionaldiscount/edit"
                   element={<EditPromotionalDiscount />}
                 />
                 <Route
                   path="/grouppromotionaldiscount/edit"
                   element={<EditGroupPromotionalDiscount />}
                 />
                 {/* //ViewPanel */}
                 <Route path="/orders/view" element={<ViewOrder />} />
                 <Route path="/orders/assign/:id" element={<AssignOrder />} />
                 <Route path="/appUsers/view" element={<ViewAppUsers />} />
                 <Route
                   path="/onlinetransaction/view"
                   element={<ViewOnlineTransaction />}
                 />
                 <Route
                   path="/orderpayments/edit"
                   element={<ViewOrderPayment />}
                 />
                 <Route
                   path="/employeewiseorders/view"
                   element={<ViewEmployeeWiseOrders />}
                 />
                 <Route path="/merchants/view" element={<ViewMerchant />} />
                 <Route path="/employees/view" element={<ViewEmployee />} />
                 <Route
                   path="/promotionaldiscount/view"
                   element={<ViewPromotionalDiscount />}
                 />
                 <Route
                   path="/grouppromotionaldiscount/view"
                   element={<ViewGroupDiscount />}
                 />
                 <Route path="/orders/orderid" element={<ConfirmOrder />} />
                 <Route path="/order/view/:id" element={<ViewOrder />} />
                 {/* <Route
           path="/orderPayment/view/:id"
           element={<ViewOrderPay />}
         /> */}
                 <Route
                   path="/orderpayments/view/:id"
                   element={<ViewOrderPayment />}
                 />
                 <Route
                   path="/employeewiseorders/view/:id"
                   element={<ViewEmployeeWiseOrders />}
                 />
                 <Route path="/appUsers/view/:id" element={<ViewAppUsers />} />
                 <Route
                   path="/deliveryrate/view/:id"
                   element={<ViewDeliveryRate />}
                 />
                  <Route
                   path="/notification"
                   element={<SendNotificationTo />}
                 />
                 <Route
                   path="/deliveryrate/edit/:id"
                   element={<EditDeliveryRate />}
                 />
                 {/* <Route path="/policy" element={<PrivacyPolicy />} /> */}
                 {/* <Route path="/line" element={<Line />} />

 
   

   <Route path="/area" element={<Area />} />
   <Route path="/bar" element={<Bar />} />
   <Route path="/pie" element={<Pie />} />
   <Route path="/financial" element={<Financial />} />
   <Route path="/color-mapping" element={<ColorMapping />} />
   <Route path="/pyramid" element={<Pyramid />} />
   <Route path="/stacked" element={<Stacked />} /> */}
               </Routes>
             </div>
             <div>
               <ToastContainer
                 position="bottom-right"
                 autoClose={2000}
                 hideProgressBar
                 newestOnTop={false}
                 closeOnClick={true}
                 rtl={false}
                 draggable
                 pauseOnHover
                 theme="colored"
                //  transition="Bounce"
               />
             </div>
             <Footer />
           </div>
         </div>
       </div>
     )}
   </>
 ); 
}   
          
