import React, { useState, useContext, useEffect } from "react";
import StateContext from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import blank from "../../assets/blank.jpg";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";

// import Alert from "@mui/material/Alert";
// import AlertTitle from "@mui/material/AlertTitle";
// import Stack from "@mui/material/Stack";
import { Stack, Alert, AlertTitle } from '@mui/material';
import { API } from "../../config/api";

const EditProfile = () => {
  const { profileData } = useContext(StateContext);
  //const { token } = useContext(StateContext);

  console.log(profileData);
 
  const [data, setData] = useState(profileData);

const alphaRegex=new RegExp('^(?=.*[a-zA-Z])[a-zA-Z -]+$');
  const handleChange = (e) => {

      const { name, value } = e.target;
      setData({ ...data, [name]: value });
 
  };
  const validate=(event)=>{
    var regex = new RegExp("^[0-9-!@#$%*?]");
    var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
        event.preventDefault();
        return false;
    }
}       
  const navigate = useNavigate();

  console.log(data);

  function mainPage() {
    navigate("/");
    // console.log(data);
  }

  //Image
  const [selectedImages, setSelectedImages] = useState([]);

  const onSelectFile = (event) => {
    // const selectedFiles = event.target.files;
    const selectedFiles = event.target.files[0];
    // const selectedFilesArray = Array.from(selectedFiles);

    // const imagesArray = selectedFilesArray.map((file) => {
    //   return file;
    // });

    setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    // FOR BUG IN CHROME
    event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  //ImagePreview
  const [image, setImage] = React.useState("");
  // const [imgUpload, setImgUpload] = useState(false);
  const [serverImage, setServerImage] = useState([""]);

  console.log("image = ", image);

  function deleteImage(e) {
    const s = image.filter((item, index) => index != e);
    setImage(s);
  }

  console.log(image);

  const ImageThumb = ({ image }) => {
    return (
      <div>
        <img src={URL.createObjectURL(image)} alt={image.name} />
      </div>
    );
  };

  const [alert, setAlert] = useState(false);
  const [error, setError] = useState(false);

  async function postData(e) {
    e.preventDefault(); // Prevent default form submission (if within a form)
    window.scrollTo({ top: 0, behavior: "smooth" });

    // if (
    //   !data.firstName ||
    //   !data.lastName ||
    //   !data.firmName ||
    //   !data.address ||
    //   !data.city ||
    //   !data.country ||
    //   !data.email ||
    //   !data.gender ||
    //   !data.mobile ||
    //   !data.pincode ||
    //   !data.state ||
    //   !data.addharCard ||
    //   !data.panCard ||
    //   !data.classification ||
    //   !data.accountNo ||
    //   !data.bankAccountType ||
    //   !data.bankName ||
    //   !data.branchName ||
    //   !data.ifscCode
    // ) {
    //   setError("All fields are mandatory. Please fill in all the details.");
    //   return;
    // }

    const id = data._id;
    const finalToken = sessionStorage.getItem("myToken");
    const uploadImage = `${API}/upload`;
    const uploadData = `${API}/auth/edit-profile`;
    const formData = new FormData();
    formData.append("Image", selectedImages[0]);

    try {
      // Upload image
      const imageResponse = await axios.post(uploadImage, formData);
      const newimg = imageResponse.data.files;
      const coverPhoto = newimg[0];

      // Prepare final data
      const finalData = {
        firstName: data.firstName,
        lastName: data.lastName,
        firmName: data.firmName,
        address: data.address,
        city: data.city,
        country: data.country,
        email: data.email,
        gender: data.gender,
        mobile: data.mobile,
        pincode: data.pincode,
        state: data.state,
        addharCard: data.addharCard,
        panCard: data.panCard,
        classification: data.classification,
        accountNo: data.accountNo,
        bankAccountType: data.bankAccountType,
        bankName: data.bankName,
        branchName: data.branchName,
        ifscCode: data.ifscCode,
        avatar: coverPhoto,
      };

      // Send profile update request
      const response = await fetch(uploadData, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-token": finalToken,
        },
        body: JSON.stringify(finalData),
      });

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }

      const responseData = await response.json();
      console.log(responseData);

      // Show success alert
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
        // mainPage();
      }, 2000);
    } catch (err) {
      console.error(err);
      setError(true);
      // Show error alert
      setTimeout(() => {
        setAlert(false);
        // mainPage();
      }, 2000);
    }
  }

  

  console.log(alert, error, "OK");

  

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        {alert && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Profile edited successfully!
          </Alert>
        </Stack>
      )}
      {error && (
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            All fields are mandatory. Please fill in all the details.
          </Alert>
        </Stack>
      )}
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Profile
      </h4>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          style={{ width: 80 }}
          onClick={() => mainPage()}
          class="backButton"
        >
          Back
        </button>
      </div>

      <form>
        <div class="grid xl:grid-cols-3  m:gap-2 form-group">
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>First Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firstName"
              value={data.firstName}
            //  onKeyDown={(e)=>validate(e)}
              // onkeypress={(e) => {
              //   var char = String.fromCharCode(e.which);
              //   if (!/[0-9]/.test(char)) {
              //     e.preventDefault();
              //   }
            
              // }}
              onChange={handleChange}
              // onkeypress={(event)=>{return (event.charCode > 64 && 
              //   event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)}}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Last Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="lastName"
              value={data.lastName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Firm Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="firmName"
              value={data.firmName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Address</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="address"
              value={data.address}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="city"
              value={data.city}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="country"
              value={data.country}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Email</label>
            <input
              type="email"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="email"
              value={data.email}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="gender"
              value={data.gender}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Mobile</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="mobile"
              value={data.mobile}
              onChange={handleChange}
              disabled
              maxLength={10}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>PinCode</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="pincode"
              value={data.pincode}
              // onChange={handleChange}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 6) {
                  handleChange(e);
                }
              }}
              maxLength={6}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="state"
              value={data.state}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Aadhar Card</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="addharCard"
              value={data.addharCard}
              // onChange={handleChange}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 12) {
                  handleChange(e);
                }
              }}
              // maxLength={12}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="panCard"
              value={data.panCard}
              onChange={handleChange}
              maxLength={10}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account No</label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="accountNo"
              value={data.accountNo}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Account Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              name="bankAccountType"
              value={data.bankAccountType}
              onChange={handleChange}
            >
              <option selected>Select Value</option>
              <option value="SAVING">Saving</option>
              <option value="CURRENT">Current</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="bankName"
              value={data.bankName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="branchName"
              value={data.branchName}
              onChange={handleChange}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>IFSC Code</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              name="ifscCode"
              value={data.ifscCode}
              onChange={handleChange}
            />
          </div>
          <div class="relative z-0 p-2 w-full mb-6 group">
          {/* <div style={{ marginLeft: 20 }}> */}
            <label>Avatar Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label
                  for="file-upload"
                  class="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={data.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="flex space-x-2 justify-end">
        <button
          type="button"
          style={{ width: 80 }}
          onClick={postData}
          class="addButton"
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
