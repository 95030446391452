import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineRollback } from "react-icons/ai";
import "../../pages/view.css";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../config/api";
import './employeeWise.css'
import ItemImage from "../../assets/Chikencury.jpg"

const ViewEmployeeWiseOrders = () => {
  const navigate = useNavigate();
  const [view, setView] = useState();
  const [successData, setSuccessData] = useState();
  const distId = sessionStorage.getItem("id");
  const { id } = useParams();
  console.log("TableDataID", id);
  // c - 0;

  async function fetchData() {
    axios
      .get(`${API}/order/user/${distId}`)
      .then((res) => {
        // console.log("first++", res.data.data);
        setSuccessData(res.data.data);
        console.log("brrro",res.data.data )
      })
      .catch((err) => {
        console.log("error++++", err.message);
      });
  }

  const HandleBack = () => {
    navigate(`/employeewiseorders`);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
  <div className="main-container-employee">
  <div className="inner-container-employee">
  <div style={{display: 'flex', width: '100%'}}>
  <h1 className="EmployeeName" style={{width: '90%'}}>Employee Name</h1>
  <button  className="addBtn" style={{padding: '0px 20px', fontSize: '20px', height: '40px'}} onClick={HandleBack}>Back</button>
  </div>
  <div className="empCrt-wise-main">
    <div className="topEmpItems">
      <div className="innerLeft-topEmpItems">
        <div><img src={ItemImage} alt="items" /></div>
        <h1>Grilled Paneer, Tomato, Mint Chutney</h1>
      </div>
      <div className="innerRight-topEmpItems">
        <p>Delivered</p>
      </div>
    </div>
    <h1>Client Name: Gajanan</h1>
    <h2>Order No: </h2>
    <div className="orderDetais">
      <h1>Order Placed On: 12 Sep 2024, 7:20 PM</h1>
      <h1> Order Amount: ₹199</h1>
    </div>
    <div className="orderDetais-deliverd">
      <h1>Order Delivered On: 15 Sep 2024, 5:20 PM</h1>
      <h1>Employee Amount: ₹10</h1>
    </div>
  </div>
  <div className="empCrt-wise-main">
    <div className="topEmpItems">
      <div className="innerLeft-topEmpItems">
        <div><img src={ItemImage} alt="items" /></div>
        <h1>Grilled Paneer, Tomato, Mint Chutney</h1>
      </div>
      <div className="innerRight-topEmpItems">
        <p style={{background: '#FFF6EE', color: '#EE882A'}}>Pending</p>
      </div>
    </div>
    <h1>Client Name: Gajanan</h1>
    <h2>Order No: </h2>
    <div className="orderDetais">
      <h1>Order Placed On: 12 Sep 2024, 7:20 PM</h1>
      <h1> Order Amount: ₹199</h1>
    </div>
    <div className="orderDetais-deliverd">
      <h1>Order Delivered On: 15 Sep 2024, 5:20 PM</h1>
      <h1>Employee Amount: ₹10</h1>
    </div>
  </div>
  <div className="empCrt-wise-main">
    <div className="topEmpItems">
      <div className="innerLeft-topEmpItems">
        <div><img src={ItemImage} alt="items" /></div>
        <h1>Grilled Paneer, Tomato, Mint Chutney</h1>
      </div>
      <div className="innerRight-topEmpItems">
        <p style={{background: '#B47AF', color: '#B47AF'}}>Shipping</p>
      </div>
    </div>
    <h1>Client Name: Gajanan</h1>
    <h2>Order No: </h2>
    <div className="orderDetais">
      <h1>Order Placed On: 12 Sep 2024, 7:20 PM</h1>
      <h1> Order Amount: ₹199</h1>
    </div>
    <div className="orderDetais-deliverd">
      <h1>Order Delivered On: 15 Sep 2024, 5:20 PM</h1>
      <h1>Employee Amount: ₹10</h1>
    </div>
  </div>
  <div className="empCrt-wise-main">
    <div className="topEmpItems">
      <div className="innerLeft-topEmpItems">
        <div><img src={ItemImage} alt="items" /></div>
        <h1>Grilled Paneer, Tomato, Mint Chutney</h1>
      </div>
      <div className="innerRight-topEmpItems">
        {/* <p style={{background: '#FFE1E1', color: '#FC0303'}}>Cancelled</p> */}
      </div>
    </div>
    <h1>Client Name: Gajanan</h1>
    <h2>Order No: </h2>
    <div className="orderDetais">
      <h1>Order Placed On: 12 Sep 2024, 7:20 PM</h1>
      {/* <h1> Order Amount: ₹199</h1> */}
    </div>
    <div className="orderDetais-deliverd">
      <h1>Order Delivered On: 15 Sep 2024, 5:20 PM</h1>
      {/* <h1>Employee Amount: ₹10</h1> */}
    </div>
  </div>
  </div>
</div>
  );
};

export default ViewEmployeeWiseOrders;


//     <div class="container">
//       {successData?.map((item, index)=>{
//        return (
// <div key={index}>
// {item._id === id ? (
     
//       <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
//         <div class="flex justify-end active">
//           <div class="backIcon">
//             <Link to="/employeewiseorders">
//               <AiOutlineRollback />
//             </Link>
//           </div>
//         </div>
//         <div class="flex justify-center text-left">
//           <ul class="bg-white rounded-lg w-96 text-gray-900 ">
//             <li class="px-6 py-2 border-b border-gray-200 w-full rounded-t-lg ">
//               Category : Subscription
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Order No :{item?.orderNo}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Order Date : {item?.createdAt}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Client : {item?.userId?.firstName}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Employee : {item?.employeeId?.firstName}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Order Amount : 
              
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Employee Amount : {item?.userId?.totalPrice}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Order Status : {item?.paymentStatus}
//             </li>
//             <li class="px-6 py-2 border-b border-gray-200 w-full">
//               Delivery Date : {item?.date}
//             </li>
//             <li class="px-6 py-2 w-full rounded-b-lg">Distance : 30 </li>
//           </ul>
//         </div>
//       </div>
//       ) : null}
//       </div>
//       );
//        })}
//     </div>
