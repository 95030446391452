import { Alert, Stack } from "@mui/material";
import React, {useState, useEffect, useContext} from "react";
import { useNavigate } from "react-router-dom";
import StateContext from "../../contexts/ContextProvider";
import axios from "axios";
import { ImCross } from "react-icons/im";
import { BsFillPencilFill } from "react-icons/bs";
import "../view.css";
import { API } from "../../config/api";
import { showErrorMsg } from "../../components/helper/message";
const EditMerchant = () => {
 
  const navigate = useNavigate();
  const { data } = useContext(StateContext);
  const [updatedValue, setUpdatedValue] = useState(data);
  const [details,setDetails] = useState([""]);
  const [alert,setAlert] = useState(false);
  const [error,setError] = useState(false);
  const [type,setType] = useState({})
  const [classify,setClassify]=useState("");
  const [alltype, setAllType] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [errMsg, setErrMsg] = useState(false);
  const [msg, setMsg] = useState("");
  var timeout;
  const displayErrMsg=(setErrMsg, timeout)=> {
    setErrMsg(true);
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      setErrMsg(false);
    },2000);
    return timeout;
  }

  useEffect(()=>{
    handleDetails();
    DomainType();
    
    console.log(type);
  },[])


  const mainPage = () => {
    navigate("/merchants");
  };

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files[0];

    const fileExtension = selectedFiles.name.split(".").at(-1);
    const allowedFileTypes = ["jpg","jpeg","png"];
    if (!allowedFileTypes.includes(fileExtension)) {
        //window.alert(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          //     alert("Only jpg/jpeg and png files are allowed!");
          setMsg(`File does not support. Files type must be ${allowedFileTypes.join(", ")}`);
          timeout = displayErrMsg(setErrMsg, timeout);
          setLoading(false)
        return false;
    }
   else {
        setSelectedImages((previousImages) =>
          previousImages.concat(selectedFiles)
        );
        event.target.value = "";
   }
    // const selectedFiles = event.target.files[0];
    // setSelectedImages((previousImages) => previousImages.concat(selectedFiles));
    // event.target.value = "";
  };

  function deleteHandler(image) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  function handleDetails() {
    
    let id = data._id;
    axios.get(`${API}/auth/byDistributor/${id}`)
    .then((res)=>{
      console.log(res)
      setDetails(res.data.data);
    })
  }
  function DomainType(){
    
    axios.get(`${API}/merchant/Type`,)
    .then((res)=>{
      
      const domain = res.data.data;
      setType(domain)
    })
  }
  console.log(details);
  function check(){
    setAllType([]);
    let domain = type;
    var merchantType = updatedValue.classification;
    if(merchantType !== ""){
    domain.map((subDomain)=>{
      if(subDomain.merchantType == merchantType){
        setAllType(alltype=>[...alltype,subDomain]);
      }
    })
  }
  else{
    setAllType([]);
  }
  }
  const handleChange = (e) => {
   debugger;
    const { name, value } = e.target;
    if(name === "domain"){
      
      let index = e.target.selectedIndex;
      var idvalue = e.target.childNodes[index].id 
      //setAddData({ ...addData, [name]: idvalue });  
      setUpdatedValue({ ...updatedValue, [name]: value });
    }
    else if(name === "classification"){
      setClassify(value);
      setAllType([]);
    }
    else{
      //setAddData({ ...addData, [name]: value });
      setUpdatedValue({ ...updatedValue, [name]: value });
    }
    // setUpdatedValue({ ...updatedValue, [name]: value });
  };

  async function handleSubmit(e) {
    
    const editId = data._id;
    e.preventDefault();
      const res = await fetch(
        `${API}/auth/distributor/${editId}`,
        {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": "token-value",
          },
          body: JSON.stringify(updatedValue),
        }
      ).then((res)=>{
        if(res.status === 200){
          setAlert(true);
          setTimeout(() => {
            navigate("/merchants");
          }, 1000);
        }
        else{
          setError(true);
          setTimeout(() => {
            navigate("/merchants");
          }, 1000);
        }
         
      })
      .catch((err)=> {
        setError(true);
          setTimeout(() => {
            navigate("/merchants");
          }, 1000);
      }
     )
  }
  const domain = function (domain){
    return <option value ={domain.domainType} id={domain._id}>{domain.domainType}</option>
  }

  return (
  
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
                 {errMsg && showErrorMsg(msg, errMsg)}
      {
        alert ?(
          <Stack sx={{ width: '30%' }} style={{position:"fixed",left:"22%",bottom:"1%", width:"30%"}} spacing={2}>
      <Alert variant="filled" severity="success">
        Merchant Updated Successfully
      </Alert>
    </Stack>
        ):(null)
      }
      {
        error ?(
          <Stack sx={{ width: '30%' }} style={{position:"fixed",left:"22%",bottom:"1%", width:"30%"}} spacing={2}>
      <Alert variant="filled" severity="failure">
        Sorry, Merchant Cannot be Updated at this moment. Please try Again!
      </Alert>
    </Stack>
        ):(null)
      }
      <h4 class="font-medium leading-tight text-4xl mt-0 mb-2 text-blue-600">
        Edit Merchant
      </h4>
      <div class="flex space-x-2 justify-end">
        <button type="submit" style={{ width: "7%" }}
            class="backButton" onClick={() => mainPage()}>
          Back
        </button>
      </div>
      <form>
        <div class="grid xl:grid-cols-3  m:gap-2">


        <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Classification</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="classification" 
              name="classification" 
              onChange={handleChange}     
              value={updatedValue.classification}
              aria-label="Default select example"
            >
              <option value="select">Select Classification</option>
              <option value="WHOLESALER">Wholesaler</option>
              <option value="RETAILER">Retailer</option>
              <option value="MANIFACTURER">Manufacturer</option>
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label="Default select example"
              onClick={check}
              onChange={handleChange}
              value= {updatedValue.merchantTypeId.domainType}
              id="domain"
              name="domain"
            >
              <option value="" >-- Select Type --</option>
              {
                alltype.map(domain)
              } 
            </select>
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group" >
            <label
             
            >
              Orbit Commission
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="commission"
              name="orbitMartCommission"
              onChange={handleChange}
              value={updatedValue.orbitMartCommission}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group" >
            <label
             
            >
              Firm Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firmName"
              name="firmName"
              onChange={handleChange}
              value={updatedValue.firmName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group" >
            <label
             
            >
              First Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="firstname"
              name="firstName"
              onChange={handleChange}
              value={updatedValue.firstName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Last Name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="lastname"
              name="lastName"
              onChange={handleChange}
              value={updatedValue.lastName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Gender</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="gender"
               name="gender" 
              onChange={handleChange}    
              value={updatedValue.gender}   
      aria-label="Default select example"
            >
              <option value="select">Select Gender</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
              
            </select>
          </div>
          
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Email
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="email"
              name="email"
              onChange={handleChange} 
              value={updatedValue.email}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Mobile Number
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="mobilenum"
              name="mobile"
              onChange={handleChange} 
              value={updatedValue.mobile}
              disabled
            />
          </div>
          
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Address
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="address"
              name="address"
              onChange={handleChange}
              value={updatedValue.address}
            />
          </div>

          {/* <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Firm name
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="shopname"
              name="firmName"
              onChange={handleChange}
              value={updatedValue.firmName}
            />
          </div> */}



          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Pin Code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pincode"
              name="pincode"
              // onChange={handleChange}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 6) {
                  handleChange(e);
                }
              }}
              value={updatedValue.pincode}
            />
          </div>


         


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Country</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="country"
              name="country"  
              onChange={handleChange}
              value={updatedValue.country}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>State</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="state"
              name="state"
              onChange={handleChange}
              value={updatedValue.state}
            />
          </div>


          

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>City</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="city"
              name="city"
              onChange={handleChange}
              value={updatedValue.city}
            />
          </div>


          

                    
          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Adhar Card </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="adharcard"
              name="addharCard"
              // onChange={handleChange}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d*$/.test(value) && value.length <= 12) {
                  handleChange(e);
                }
              }}
              value={updatedValue.addharCard}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Pan Card</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="pancard"
              name="panCard"
              onChange={handleChange}
              value={updatedValue.panCard}
              maxLength={10}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Bank Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="bankname"
              name="bankName"
              onChange={handleChange}
              value={updatedValue.bankName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Branch Name</label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="branchname"
              name="branchName"
              onChange={handleChange}
              value={updatedValue.branchName}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label>Account type</label>
            <select
              class="form-select 
      w-full
      px-3
      py-1.5
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="acctype"
              name="bankAccountType"
              onChange={handleChange}  
              value={updatedValue.bankAccountType}      
      aria-label="Default select example"
            >
              <option value="select ">Select Account type</option>
              <option value="SAVING">Saving Account</option>
              <option value="CURRENT">Current Account</option>
            </select>
          </div>


          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              Bank Account Number
            </label>
            <input
              type="number"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="accnumber"
              name="accountNo"
              onChange={handleChange}
              value={updatedValue.accountNo}
            />
          </div>

          <div class="relative z-0 p-2 w-full mb-6 group">
            <label
             
            >
              IFSC Code
            </label>
            <input
              type="text"
              class="form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="ifsccode"
              name="ifscCode"
              onChange={handleChange}
              value={updatedValue.ifscCode}
            />
          </div>

          <div>
            <label style={{paddingLeft:"12px"}}>Image</label>
            <div class="relative mt-1 flex justify-center w-40">
              <div class=" absolute right-0 top-0 z-10 flex">
                <label>
                  <BsFillPencilFill />
                  <input
                    id="file-upload"
                    name="file-upload"
                    disabled={selectedImages.length == 1}
                    accept="image/*"
                    type="file"
                    onChange={onSelectFile}
                    class="sr-only"
                  />
                </label>
              </div>
              <div class="relative z-0 p-2 w-full mb-6 group">
                {selectedImages.length > 0 ? (
                  selectedImages.map((item, index) => {
                    return (
                      <div style={{ display: "inline-block" }} key={item}>
                        <p className="block-icon">
                          <img src={URL.createObjectURL(item)} alt="img" />
                          <ImCross
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteHandler(item)}
                            className="icon-belowtag"
                          />
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <img src={updatedValue.avatar} alt="user-profile" />
                  </div>
                )}
              </div>
            </div>
          </div>      

      </div>
      </form>
      <div class="flex space-x-2 justify-end">
          <button
            style={{ width: "8%" }}
            type="submit"
            onClick={handleSubmit}
            class="addButton"
          >
            Update
          </button>
        </div>
    </div>
  );
};

export default EditMerchant;
